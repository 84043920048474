/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './Components/HomePage';

function App() {
  //Need this for testing purpose!
  console.log('Env', process.env)
  return (
    <BrowserRouter>
      <HomePage />
    </BrowserRouter>
  );
}

export default App;
