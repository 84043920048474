/// Copyright 2024 Hitachi Energy. All rights reserved.

import {
  Configuration,
  PublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser';
import { AHGDomains } from '../../const/appConstants';

export interface AuthUser {
  name: string;
  username: string;
}

export interface AuthRedirectResponse {
  subscribers: any[];
  subscribe: (subscriber: (response: any, error: any) => void) => void;
  onAuthResult: (response: any, error: any) => void;
}

const getClientId = (hostName: string) => {
  const devHitachiClientId = 'd179458a-8037-47f4-9f78-d6b2136bb2ec';
  switch (hostName) {
    case AHGDomains.dev:
      return devHitachiClientId;
    case AHGDomains.test:
      return '5083bc15-cc5f-423a-be6f-554826bea381';
    case AHGDomains.prod:
      return '0f9e4c63-77c6-4517-9e9c-409cab466bc4';
    default:
      return devHitachiClientId;
  }
};

const auth = {
  tenant_id: '7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195',
  client_id: getClientId(window.location.hostname),
  scope: `api://${getClientId(window.location.hostname)}/User.Login.Read`
};

// MSAL Configuration
export const authConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${auth.tenant_id}/`,
    clientId: auth.client_id,
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: `${window.location.origin}/`,
    validateAuthority: true,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
} as Configuration;

export const authParameters = {
  scopes: [auth.scope]
} as RedirectRequest;

let msalInstance: PublicClientApplication;

export const getMsalInstance = async () => {
  msalInstance ??= new PublicClientApplication(authConfig);
  await msalInstance.initialize();

  return msalInstance;
};

