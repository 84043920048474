/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useState } from 'react';
import {
  HvVerticalNavigation,
  HvVerticalNavigationTree
} from '@hitachivantara/uikit-react-core';
import { Components, BarChart } from '@hitachivantara/uikit-react-icons';
import './AppNavigation.scss';
import { useNavigate } from 'react-router-dom';

const AppNavigation = () => {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState<string>('');
  const renderPage = useCallback(
    (_event: React.SyntheticEvent, page: { id: string }) => {
      const { id } = page;
      setSelectedPage(id);
      switch (id) {
        case 'asset':
          navigate(`/${id}?type=microscada`);
          break;
        case 'lifecycle':
          navigate(`/${id}`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  return (
    <div className='navbar'>
      <HvVerticalNavigation
        useIcons
        open={false}
        data-testid='vertical-navigation'
      >
        <HvVerticalNavigationTree
          data-testid='vertical-navigation-tree'
          collapsible
          data={[
            {
              icon: <Components />,
              id: 'asset',
              label: 'Asset Inventory'
            },
            {
              icon: <BarChart style={{ rotate: '90Deg' }} />,
              id: 'lifecycle',
              label: 'Life Cycle'
            }
          ]}
          defaultExpanded
          onChange={renderPage}
          selected={selectedPage}
        />
      </HvVerticalNavigation>
    </div>
  );
};
export default AppNavigation;
