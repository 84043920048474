/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import {
  HvHeader,
  HvHeaderBrand,
  HvGrid,
  HvPanel,
  HvHeaderActions
} from '@hitachivantara/uikit-react-core';

import hitachiLogo from './assets/hitachi-energy.svg';
import AppNavigation from './AppNavigation/AppNavigation';
import './HomePage.scss';
import AppRoutes from '../routes/AppRoutes';
import UserInformation from './UserInformation/UserInformation';

const HomePage = () => {
  return (
    <>
      <HvHeader position='sticky'>
        <HvHeaderBrand
          id='homepage-header'
          logo={
            <img
              className='logo'
              src={hitachiLogo}
              alt='Hitachi logo'
            />
          }
          name='Asset Health Guardian'
          data-testid='app-header'
        />
        <HvHeaderActions>
          <UserInformation />
        </HvHeaderActions>
      </HvHeader>

      <HvGrid
        container
        direction='row'
        className='full-height'
      >
        <HvGrid
          item
          xs={1}
        >
          <AppNavigation />
        </HvGrid>
        <HvGrid
          item
          xs={11}
          className='main-screen'
        >
          <HvPanel>
            <HvGrid
              container
              className='container'
            >
              <HvGrid
                item
                className='mainScreenPanel'
              >
                <AppRoutes />
              </HvGrid>
            </HvGrid>
          </HvPanel>
        </HvGrid>
      </HvGrid>
    </>
  );
};

export default HomePage;
