/// Copyright 2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from 'react';
import { HvButton, HvGrid } from '@hitachivantara/uikit-react-core';
import { useNavigate } from 'react-router-dom';
import hitachiInspireNextLogo from '../../Components/assets/CS_Logo_FullColor_RGB.svg';
import { CoreRoutes } from '../../const/appConstants';
import './Login.scss';
import { useIsAuthenticated } from '@azure/msal-react';

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleSignInButtonClick = useCallback(() => {
    const handleLogin = async () => {
      if (isAuthenticated) {
        navigate(CoreRoutes.home);
        return;
      }
      navigate(CoreRoutes.homeAuth);
    };

    handleLogin();
  }, [navigate, isAuthenticated]);

  return (
    <HvGrid
      container
      className='login-wrapper'
    >
      <HvGrid
        xs={11}
        sm={8}
        md={5}
        className='login-dialog'
      >
        <HvGrid
          container
          spacing={0}
          justifyContent='center'
          className='login-screen-wrapper'
        >
          <HvGrid xs={12}>
            <HvGrid
              xs={12}
              className='login-hitachi-next-logo'
            >
              <img
                src={hitachiInspireNextLogo}
                alt='Hitachi logo'
              />
            </HvGrid>
            <HvGrid
              xs={12}
              className='Cop-title'
            >
              Login
            </HvGrid>
            <HvGrid xs={12}>
              <HvButton
                className='sign-in-button'
                variant='primary'
                onClick={handleSignInButtonClick}
              >
                Sign In
              </HvButton>
            </HvGrid>
          </HvGrid>
        </HvGrid>
      </HvGrid>
    </HvGrid>
  );
};

export default Login;
