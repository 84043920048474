/// Copyright 2024 Hitachi Energy. All rights reserved.

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AssetInventory from '../pages/AssetInventory/AssetInventory';
import Lifecycle from '../pages/Lifecycle/Lifecycle';
import { CoreRoutes } from '../const/appConstants';
import AuthPage from '../pages/AuthPage/AuthPage';
import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import { useIsAuthenticated } from '@azure/msal-react';

const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Routes>
      <Route
        path={CoreRoutes.login}
        element={<Login />}
      />
      <Route
        path={CoreRoutes.homeAuth}
        element={<AuthPage />}
      />
      <Route
        path={CoreRoutes.home}
        element={isAuthenticated ? <Dashboard /> : <Login />}
      />
      <Route
        path={CoreRoutes.asset}
        element={<AssetInventory />}
      />
      <Route
        path={CoreRoutes.lifecycle}
        element={<Lifecycle />}
      />
      <Route
        path={CoreRoutes.logout}
        element={<Logout />}
      />
    </Routes>
  );
};

export default AppRoutes;
